import axios from "axios";
import React, { useState } from "react";
import { BackArrow, LoadingSvg, SuccessTick } from "../../../SVGs/SVGs";
import * as ContactFormStyles from "./ContactForm.module.scss";

const ContactForm = () => {
  const [step, setStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  });

  const stepHandler = (moveTo) => {
    if (moveTo === "forward") {
      setStep((prevState) => prevState + 1);
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  const formDataChangeHandler = (key, value) => {
    const updatedFormData = { ...contactForm };
    updatedFormData[key] = value;

    setContactForm(updatedFormData);
  };

  const contactFormSubmitHandler = (e) => {
    e.preventDefault();
    if (step >= 4) {
      setIsSubmitted(true);
      axios
        .post(
          "https://sheet.best/api/sheets/89d42962-4637-48a1-b16e-d4d53c80edc3",
          {
            email: contactForm.email,
            name: contactForm.name,
            phone: contactForm.phone,
            company: contactForm.company,
            message: contactForm.message,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccessfullySubmitted(true);
            // setFormMessage("You've successfully subscribed to our newsletter.");
          }
        });
    } else {
      stepHandler("forward");
    }
  };

  return (
    <section
      className={`contact-form-wrapper ${ContactFormStyles.ContactFormWrapper}`}
    >
      <div className={ContactFormStyles.ContactFormContent}>
        {!successfullySubmitted ? (
          <form onSubmit={contactFormSubmitHandler}>
            <div className={ContactFormStyles.ContactFormSteps}>
              <div className={`step step-1 ${step === 1 ? "show" : ""}`}>
                <input
                  autoComplete="off"
                  type="text"
                  name="name"
                  placeholder="your name"
                  value={contactForm.name}
                  onChange={(e) =>
                    formDataChangeHandler("name", e.target.value)
                  }
                  // autoFocus="on"
                />
              </div>
              <div className={`step step-2 ${step === 2 ? "show" : ""}`}>
                <input
                  autoComplete="off"
                  type="email"
                  name="email"
                  placeholder="email"
                  value={contactForm.email}
                  onChange={(e) =>
                    formDataChangeHandler("email", e.target.value)
                  }
                />
              </div>
              <div className={`step step-3 ${step === 3 ? "show" : ""}`}>
                <input
                  autoComplete="off"
                  type="text"
                  name="company"
                  placeholder="company name"
                  className="es-mb-120"
                  value={contactForm.company}
                  onChange={(e) =>
                    formDataChangeHandler("company", e.target.value)
                  }
                />
                <input
                  autoComplete="off"
                  type="phone"
                  name="phone"
                  placeholder="Phone"
                  value={contactForm.phone}
                  onChange={(e) =>
                    formDataChangeHandler("phone", e.target.value)
                  }
                />
              </div>
              <div className={`step step-4 ${step === 4 ? "show" : ""}`}>
                <textarea
                  rows="5"
                  placeholder="List your concern here"
                  value={contactForm.message}
                  onChange={(e) =>
                    formDataChangeHandler("message", e.target.value)
                  }
                ></textarea>
              </div>
            </div>
            <div className={ContactFormStyles.FormFooter}>
              <div className={ContactFormStyles.FormFooterContent}>
                <div className={ContactFormStyles.FormFooterNextSubmit}>
                  <div className={ContactFormStyles.FormFooterBtns}>
                    <button
                      className={step >= 4 ? "fadeOut" : ""}
                      onClick={() => stepHandler("forward")}
                      type="button"
                    >
                      Next
                    </button>

                    <button
                      className={step < 4 ? "fadeOut" : ""}
                      type="submit"
                      disabled={isSubmitted ? true : false}
                    >
                      Send
                      {isSubmitted && <LoadingSvg />}
                    </button>
                  </div>
                  <p>
                    Eager to reach us? Tell us all about it, we are listening.
                    May it’s take 1min to fill the form
                  </p>
                </div>
                <div className={ContactFormStyles.FormFooterBack}>
                  <span
                    className={step > 1 ? "showBackStep" : ""}
                    onClick={() => stepHandler("back")}
                  >
                    <BackArrow color="white" />
                  </span>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <div className={ContactFormStyles.ContactFormSuccess}>
            <SuccessTick />
            <h4>Thanks for being awesome! </h4>
            <p>
              Our team will be in touch with you shortly at the email or phone
              number you have provided us. Please enjoy, and let us know if
              there’s anything else we can help you with.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContactForm;
