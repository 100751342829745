// extracted by mini-css-extract-plugin
export var ContactFormWrapper = "ContactForm-module--ContactFormWrapper--tSayY";
export var ContactFormContent = "ContactForm-module--ContactFormContent--S3MCA";
export var ContactFormSuccess = "ContactForm-module--ContactFormSuccess--P3aF5";
export var ContactFormSteps = "ContactForm-module--ContactFormSteps--CoFOr";
export var FormFooter = "ContactForm-module--FormFooter--+6JsV";
export var FormFooterContent = "ContactForm-module--FormFooterContent--JP9Q9";
export var FormFooterNextSubmit = "ContactForm-module--FormFooterNextSubmit--yXUQ8";
export var FormFooterBack = "ContactForm-module--FormFooterBack--d68TG";
export var FormFooterBtns = "ContactForm-module--FormFooterBtns--G64g1";
export var ContactFormStep = "ContactForm-module--ContactFormStep--G8Nng";