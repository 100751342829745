import React, { useLayoutEffect, useRef } from "react";
import CTAButton from "../../../CTAButton/CTAButton";
import { Email, LinkedIn, Upwork } from "../../../SVGs/SVGs";
import * as GreaterSuccessStyles from "./GreaterSuccess.module.scss";
import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";
import { gsap } from "gsap";

const GreaterSuccessGrid = [
  {
    title: "Extremely Suave",
    desc: `Write to us and we shall reply soon.`,
    btnText: "Emails Us",
    icon: <Email color="white" />,
    href: "mailto:hello@endshift.io",
  },
  {
    title: "With Our Community",
    desc: `Get connected to our work profiles, pages, and more.`,
    btnText: "LinkedIn",
    icon: <LinkedIn color="white" />,
    href: "https://www.linkedin.com/company/endshiftltd/",
  },
  {
    title: "Our Natural Habitat",
    desc: `Take a look at the 100% client satisfaction score.`,
    btnText: "Upwork",
    icon: <Upwork color="white" />,
    href: "https://www.upwork.com/ag/endshift/",
  },
];

const GreaterSuccess = () => {
  const GSVectorRef = useRef();

  useLayoutEffect(() => {
    FadeInUPTextAnimation("GSText-wrapper", 1.2);

    GSVectorRef.current.style.strokeDasharray =
      GSVectorRef.current.getTotalLength();

    gsap.fromTo(
      GSVectorRef.current,
      {
        strokeDashoffset: GSVectorRef.current.getTotalLength() - 4,
      },
      {
        delay: 1.5,
        duration: 2,
        strokeDashoffset: 0,
      }
    );
  }, []);
  return (
    <section
      className={`es-section__dark greater-success-section GSText-wrapper ${GreaterSuccessStyles.GreaterSuccessWrapper}`}
    >
      <div className={GreaterSuccessStyles.GreaterSuccessContent}>
        <div className={GreaterSuccessStyles.GreaterSuccessText}>
          <h1>Let’s Unlock Greater Success Together</h1>
          <p>
            Interested in our company, people or products? Tell us all about it
            today. We try to be super responsive.
          </p>
        </div>
        <div className={GreaterSuccessStyles.GreaterSuccessVector}>
          <svg
            width="605"
            height="328"
            viewBox="0 0 605 328"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ref={GSVectorRef}
              d="M706.609 151.081C693.367 144.934 659.792 129.384 631.431 116.36C595.978 100.079 562.917 78.4788 544.449 147.403C537.779 179.596 555.705 264.983 467.769 285.078C444.868 292.812 337.957 224.467 415.916 174.118C433.85 162.536 506.108 167.224 468.075 265.732C456.754 289.873 447.264 325.197 380.165 325.656C366.51 325.883 350.926 333.314 254.878 267.807C234.476 264.295 200.533 278.514 185.098 209.337C187.919 204.648 204.004 199.182 211.693 197.035C214.7 196.38 220.047 197.942 217.386 209.425C215.267 220.44 214.034 236.782 213.682 243.577C215.727 247.837 221.854 251.835 230.009 233.745C239.617 215.63 252.611 190.856 257.906 180.733C261.017 176.485 270.154 168.512 281.814 170.607C293.114 170.13 311.02 166.462 318.56 164.687C326.574 163.733 337.537 158.917 317.279 147.292C297.124 135.278 207.324 83.7251 164.943 59.4502L74.8089 10.8711C57.6632 1.21159 29.1817 -9.38829 52.421 25.4884C75.0886 62.4988 100.597 111.086 103.681 132.009C107.369 149.074 116.347 184.076 120.376 199.444C121.002 207.21 127.446 219.574 148.213 206.903C169.657 199.839 197.998 190.668 209.489 186.965C212.245 186.136 215.079 182.31 204.369 173.639C191.091 158.36 117.035 79.5215 81.6675 42.012C69.996 29.4799 57.4307 11.209 100.542 38.3824C137.576 68.7188 215.121 133.358 249.264 161.885C252.363 163.565 255.887 171.005 245.195 187.326C235.187 203.83 213.626 242.002 204.096 259.025C202.88 261.653 194.705 265.37 171.733 259.214C148.761 253.059 48.9938 226.326 1.98194 213.73"
              stroke="url(#paint0_linear_1347_1659)"
              strokeWidth="2.75"
              strokeMiterlimit="2.61"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1347_1659"
                x1="-0.851665"
                y1="212.295"
                x2="283.143"
                y2="387.526"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.197816" stopColor="#0066CC" />
                <stop offset="0.714684" stopColor="#EB3300" />
                <stop offset="1" stopColor="#EB8D00" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className={GreaterSuccessStyles.GreaterSuccessGrid}>
        {GreaterSuccessGrid.map((item, index) => (
          <div
            className={`howWeWorkText ${GreaterSuccessStyles.GreaterSuccessGridItem}`}
            key={index}
          >
            <div className={GreaterSuccessStyles.GreaterSuccessGridItemContent}>
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
              <CTAButton
                type="button"
                size="btn-sm"
                text={item.btnText}
                rounded={true}
                outlined={false}
                btnBG={`es-btn-blue`}
                spacingClasses="es-mt-auto"
                additionalClass=""
                hasIcon={true}
                icon={item.icon}
                reverse={true}
                isLink={true}
                isShadow={false}
                href={item.href}
                btnTextColor="var(--white)"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default GreaterSuccess;
